//
//
//
//
//
//
// import HelloWorld from './components/HelloWorld.vue'
// import Loading from "./plugIn/loading"
export default {
  name: 'App',
  components: {// Loading
  },
  computed: {},
  mounted: function mounted() {// console.log("################")
    // console.log(this.LOADING)
  }
};
var loading = {
  state: {
    LOADING: false
  },
  mutations: {
    showLoading: function showLoading(state) {
      // console.log("22222222222")
      state.LOADING = true;
    },
    hideLoading: function hideLoading(state) {
      state.LOADING = false;
    }
  }
};
export default loading;
import "core-js/modules/es.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  webopenid: function webopenid(state) {
    return state.app.webopenid;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  state: function state(_state) {
    return _state.user.state;
  },
  shopId: function shopId(state) {
    return state.user.shopId;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  status: function status(state) {
    return state.user.status;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  usertype: function usertype(state) {
    return state.user.usertype;
  },
  phone: function phone(state) {
    return state.user.phone;
  },
  LOADING: function LOADING(state) {
    return state.loading.LOADING;
  },
  setting: function setting(state) {
    return state.user.setting;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  logistics: function logistics(state) {
    return state.logistics.list;
  }
};
export default getters;
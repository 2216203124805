import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import Cookies from 'js-cookie';
import { website } from "./website";
var TokenKey = 'token';
export function getToken() {
  var cookie = Cookies.get(TokenKey);
  return cookie;
}
export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: 365 * 10
  });
}
export function removeToken() {
  Cookies.remove('token');
  return Cookies.remove(TokenKey);
}
export function GetDomainOpenid() {
  var domain = document.domain; // console.log("domain",domain);

  var domainList = website.domain;
  var webopenid = '0000'; //默认的

  domainList.forEach(function (item) {
    if (item.host === domain) {
      webopenid = item.openid;
      console.log("host", item.host);
      console.log(item.openid);
      console.log(webopenid);
    }
  });
  return webopenid;
}
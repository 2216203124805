// import { param2Obj } from '@/utils'
var userMap = {
  admin: {
    code: 200,
    roles: ['admin'],
    userType: 1,
    contactPhone: '13720407904',
    token: 'admin',
    introduction: '我是超级管理员',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Super Admin'
  },
  editor: {
    code: 200,
    roles: ['editor'],
    token: 'editor',
    introduction: '我是编辑',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Normal Editor'
  }
};
export default {
  loginByUsername: function loginByUsername(config) {
    return userMap['admin'];
  },
  getUserInfo: function getUserInfo(config) {
    return userMap['admin']; // const { token } = param2Obj(config.url)
    //
    //
    //
    // if (userMap[token]) {
    //   return userMap[token]
    // } else {
    //   return false
    // }
  },
  logout: function logout() {
    return 'success';
  }
};
import request from '@/utils/request'; // import i18n from '@/lang'
// import defaultSetting from '@/settings'

export function redirectToLogin(url) {
  // window.location.href = process.env.LOGIN_URL + "?callbackUrl="+ data;
  //   console.log("#####login###")
  window.location.href = url;
}
export function doLogin(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data: data
  });
}
export function getUserInfo() {
  return request({
    url: '/user/getCurrentUser',
    method: 'GET' // params: { token }

  });
} //发送验证码

export function sendCode(data) {
  return request({
    url: '/user/sendCode',
    method: 'post',
    data: data // params: { token }

  });
} //用户注册

export function register(data) {
  return request({
    url: '/user/register',
    method: 'post',
    data: data
  });
} //重置密码

export function resetPassword(data) {
  return request({
    url: '/user/resetPassword',
    method: 'post',
    data: data
  });
} // 修改密码

export function updatePassword(data) {
  return request({
    url: '/user/updatePassword',
    method: 'post',
    data: data
  });
} //获取首页数据

export function getHome() {
  return request({
    url: '/index',
    method: 'get'
  });
} //找回密码验证码

export function passwordCode(data) {
  return request({
    url: '/user/passwordCode',
    method: 'post',
    data: data // params: { token }

  });
} //获取用户的openid

export function WxOpenId(data) {
  return request({
    url: '/wechat/getOpenId',
    method: 'GET',
    params: data
  });
} //通过微信openid 进行登陆

export function WxOpenIdLogin(data) {
  return request({
    url: '/user/login/openId',
    method: 'POST',
    data: data
  });
} //检测用户设备的绑定情况   check

export function myCheck() {
  return request({
    url: '/index/check',
    method: 'GET'
  });
} //短信验证码登陆

export function loginByCode(data) {
  return request({
    url: '/user/loginByCode',
    method: 'POST',
    data: data
  });
}
/**
 * 获取图片验证码
 * @param data
 */

export function getcaptcha(params) {
  return request({
    url: '/captcha',
    method: 'get',
    params: params
  });
}
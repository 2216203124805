import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:\\www\\youzan\\hello-world\\node_modules\\@babel\\runtime/helpers/esm/objectSpread2";

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * { path: '*', redirect: '/404', hidden: true }
 * @type {{mutations: {SET_ROUTERS: permission.mutations.SET_ROUTERS}, state: {routers: *, addRouters: Array}, actions: {GenerateRoutes1({commit: *}, *): *, GenerateRoutes({commit: *}, *=): *}}}
 */


function filterAsyncMenus(menuList, menus) {
  var res = [];
  menus.forEach(function (menu) {
    var tmp = _objectSpread({}, menu);

    var m = findMenuObject(menuList, tmp.url);

    if (menu.children.length > 0) {
      var childrenArr = [];
      var childrens = menu.children;
      childrens.forEach(function (children) {
        var tmp = _objectSpread({}, children);

        var mm = findMenuObject(menuList, tmp.url);
        childrenArr.push(mm); //查询并行菜单权限
      });
      m.children = childrenArr;
    }

    res.push(m);
  });
  res.push({
    path: '*',
    redirect: '/404',
    hidden: true
  });
  return res;
}

function findMenuObject(menuList, path) {
  var menuObject = {};
  menuList.forEach(function (menu) {
    if (menu.path === path) {
      menuObject = menu.content;
    }
  });
  return menuObject;
}

var permission = {};
export default permission;
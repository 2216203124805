import "core-js/modules/es.object.to-string";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import axios from 'axios';
import store from '@/store';
import { getToken } from '@/utils/auth';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: 120 * 1000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  // Do something before request is sent
  // console.log(store.getters.token)
  if (store.getters.token) {
    config.headers['token'] = getToken(); // 让每个请求携带token-- ["token"]为自定义key

    config.headers['X-Tag'] = 'app';
  }

  config.headers['webOpenid'] = store.getters.webopenid;
  NProgress.start();
  return config;
}, function (error) {
  // loadingInstance.clear();
  console.log(error); // for debug

  Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  // store.commit('hideLoading')
  // loadingInstance.clear();
  NProgress.done();
  var res = response.data; // return  false;

  if (res.code !== 0) {
    //出错
    if (res.code == 2234 || res.code == 2210) {
      return Promise.reject(res);
    } // console.log(res)


    if (res.code == 2229) {
      //设备解绑，然后再次进入设备
      window.location.href = '/'; //返回这页面

      return new Promise(function (resolve, reject) {}).catch(function (e) {
        console.log(e);
      });
    }

    if (res.code == 2202) {
      _Notify({
        type: 'danger',
        message: '未登录'
      });

      store.dispatch('FedLogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    }

    _Notify({
      type: 'warning',
      message: res.msg
    });

    return Promise.reject(res); // return new Promise((resolve, reject) => {}).catch((e) => {
    // 	console.log(e);
    // });
  } else {
    return res;
  }
}, function (error) {
  // loadingInstance.clear();
  NProgress.done();
  return Promise.reject(error);
});
export default service;
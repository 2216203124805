import Mock from 'mockjs';
import loginAPI from './login'; // import articleAPI from './article'
// import remoteSearchAPI from './remoteSearch'
// import transactionAPI from './transaction'
// import operationAPI from  './operation'
// import machineRepairs from './machineRepairs'
//
// import staffAPI from './staff'
// Mock.setup({
//   timeout: '350-600'
// })
// 登录相关

Mock.mock(/\/rms\/login/, 'post', loginAPI.loginByUsername); // Mock.mock(/\/login\/logout/, 'post', loginAPI.logout)
// Mock.mock(/\/user\/getCurrentUser/, 'post', loginAPI.getUserInfo)
// 文章相关
// Mock.mock(/\/article\/list/, 'get', articleAPI.getList)
// Mock.mock(/\/article\/detail/, 'get', articleAPI.getArticle)
// Mock.mock(/\/article\/pv/, 'get', articleAPI.getPv)
// Mock.mock(/\/article\/create/, 'post', articleAPI.createArticle)
// Mock.mock(/\/article\/update/, 'post', articleAPI.updateArticle)
// 搜索相关

/*Mock.mock(/\/search\/user/, 'get', remoteSearchAPI.searchUser)*/
// 账单相关

/*Mock.mock(/\/transaction\/list/, 'get', transactionAPI.getList)*/
// 员工相关

/*Mock.mock(/\/staff\/list/, 'get', staffAPI.getList)
Mock.mock(/\/staff\/create/, 'post', staffAPI.createArticle)
Mock.mock(/\/staff\/update/, 'post', staffAPI.updateArticle)*/
//运营管理部分
// Mock.mock(/\/operation\/queryOperation/, 'post', operationAPI.getList)   ///operation/queryOperation
//
// //报修模拟数据
// Mock.mock(/\/machineRepairs\/querylist/, 'post', operationAPI.getList)

export default Mock;
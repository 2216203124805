export var website = {
  '0000': {
    webname: '深圳市新国都支付技术有限公司',
    copyright: ' Copyright © 2021-2022 ',
    sitename: '新国都云打印',
    recordNumber: ''
  },
  '1001': {
    webname: '',
    copyright: ' Copyright © 2021-2022 ',
    sitename: '艾印云打印',
    recordNumber: '粤ICP备16107673号-4'
  },
  domain: [{
    'openid': '0000',
    'host': 'wxcloudprint.xgd.com'
  }, //新国都
  {
    'openid': '0000',
    'host': 'localhost'
  }, {
    'openid': '0000',
    'host': '10.18.136.30'
  }, {
    'openid': '1001',
    'host': '10.150.134.191'
  }, {
    'openid': '1001',
    'host': 'maak.cn'
  }, {
    'openid': '1001',
    'host': 'www.maak.cn'
  } //http://10.150.134.191/ printservice.xgd.com
  ]
};
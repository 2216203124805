var logistics = {
  state: {
    list: [{
      'comCode': 'ems',
      'expressName': 'EMS'
    }, {
      'comCode': 'shentong',
      'expressName': '申通快递'
    }, {
      'comCode': 'shunfeng',
      'expressName': '顺丰速运'
    }, {
      'comCode': 'yuantong',
      'expressName': '圆通速递'
    }, {
      'comCode': 'yunda',
      'expressName': '韵达快递'
    }, {
      'comCode': 'zhongtong',
      'expressName': '中通快递'
    }, {
      'comCode': 'huitongkuaidi',
      'expressName': '百世快递'
    }, {
      'comCode': 'zhaijisong',
      'expressName': '宅急送'
    }, {
      'comCode': 'tiantian',
      'expressName': '天天快递'
    }, {
      'comCode': 'quanfengkuaidi',
      'expressName': '全峰快递'
    }, {
      'comCode': 'cces',
      'expressName': 'CCES/国通快递'
    }, {
      'comCode': 'suer',
      'expressName': '速尔快递'
    }, {
      'comCode': 'kuaijiesudi',
      'expressName': '快捷速递'
    }, {
      'comCode': 'youyou',
      'expressName': '优优速递'
    }, {
      'comCode': 'yousutongda',
      'expressName': '优速通达'
    }, {
      'comCode': 'quanyikuaidi',
      'expressName': '全一快递'
    }]
  }
};
export default logistics;
import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';

var layout = function layout() {
  return import("@/components/Layout/layout");
};

var Findpass = function Findpass() {
  return import("@/components/Findpass");
};

var Swiper = function Swiper() {
  return import("@/components/Swiper");
};

var Authorization = function Authorization() {
  return import("@/components/Authorization");
};

var Unbundling = function Unbundling() {
  return import("@/components/Unbundling");
};

Vue.use(Router);
export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [{
    path: '/login',
    name: 'Login',
    component: function component() {
      return import('@/components/Login');
    }
  }, {
    path: '/register',
    name: 'Register',
    component: function component() {
      return import('@/components/Register');
    }
  }, {
    path: '/findpass/:id',
    name: 'Findpass',
    component: Findpass
  }, {
    path: '/findpass',
    name: 'Findpass',
    component: Findpass
  }, {
    path: '/swiper',
    name: 'Swiper',
    component: Swiper
  }, {
    path: '/authorization',
    name: 'Authorization',
    component: layout,
    redirect: '/authorization/list',
    children: [{
      path: 'list',
      component: Authorization,
      name: 'AuthorizationList',
      meta: {
        title: 'AuthorizationList',
        noCache: true
      }
    }, {
      path: 'unbundling',
      component: Unbundling,
      name: 'Unbundling',
      meta: {
        title: 'Unbundling',
        noCache: true
      }
    }, {
      path: 'details/:id',
      component: function component() {
        return import('../components/ShopDetails');
      },
      name: 'ShopDetails',
      meta: {
        title: 'ShopDetails',
        noCache: true
      }
    }, {
      path: 'bindEleRetail',
      component: function component() {
        return import('../components/BindEleRetail');
      },
      name: 'bindEleRetail',
      meta: {
        title: 'bindEleRetail',
        noCache: true
      }
    }]
  }, {
    path: '/test',
    name: 'test',
    component: function component() {
      return import('../components/demo/index');
    }
  }, {
    path: '/tms/elecallback/webprint',
    name: 'AuthorizationLoading',
    component: Authorization
  }, {
    path: '/print',
    component: layout,
    hidden: true,
    children: [{
      path: 'list',
      component: function component() {
        return import('../components/print/list');
      },
      name: 'printList',
      meta: {
        title: 'printList',
        noCache: true
      }
    }, {
      path: 'batch',
      component: function component() {
        return import('../components/print/batch');
      },
      name: 'batch',
      meta: {
        title: 'batch',
        noCache: true
      }
    }, {
      path: 'orderDetail/:date/:id',
      component: function component() {
        return import('../components/print/orderDetail');
      },
      name: 'orderDetail',
      meta: {
        title: 'orderDetail',
        noCache: true
      }
    }]
  }, {
    path: '/device',
    component: layout,
    hidden: true,
    children: [{
      path: 'add',
      component: function component() {
        return import('../components/device/AddDevice');
      },
      name: 'AddDevice',
      meta: {
        title: 'addDevice',
        noCache: true
      }
    }, {
      path: 'list',
      component: function component() {
        return import('../components/device/DeviceList');
      },
      name: 'DeviceList',
      meta: {
        title: 'DeviceList',
        noCache: true
      }
    }, {
      path: 'detail/:id*',
      component: function component() {
        return import('../components/device/DeviceDetail');
      },
      name: 'DeviceDetail',
      meta: {
        title: 'DeviceDetail',
        noCache: true
      }
    }, {
      path: 'relation/:id*',
      component: function component() {
        return import('../components/device/DeviceRelation');
      },
      name: 'DeviceRelation',
      meta: {
        title: 'DeviceRelation',
        noCache: true
      }
    }, {
      path: 'setting/:id*',
      component: function component() {
        return import('../components/device/DeviceSetting');
      },
      name: 'DeviceSetting',
      meta: {
        title: 'DeviceSetting',
        noCache: true
      }
    }]
  }, {
    path: '/setting',
    component: layout,
    hidden: true,
    children: [{
      path: 'relation',
      component: function component() {
        return import('../components/setting/Relation');
      },
      name: 'Relaction',
      meta: {
        title: 'Relaction',
        noCache: true
      }
    }, {
      path: 'relationprint',
      component: function component() {
        return import('../components/setting/RelationPrint');
      },
      name: 'RelationPrint',
      meta: {
        title: 'RelationPrint',
        noCache: true
      }
    }, {
      path: 'preview/:type/:groupId',
      component: function component() {
        return import('../components/setting/preview');
      },
      name: 'preview',
      meta: {
        title: 'preview',
        noCache: true
      }
    }, {
      path: 'relationtemp/:id*',
      component: function component() {
        return import('../components/setting/RelationTemp');
      },
      name: 'RelationTemp',
      meta: {
        title: 'RelationTemp',
        noCache: true
      }
    }, {
      path: 'relationcontent',
      component: function component() {
        return import('../components/setting/RelationContent');
      },
      name: 'RelationContent',
      meta: {
        title: 'RelationContent',
        noCache: true
      }
    }, {
      path: 'myrelation/:type*',
      component: function component() {
        return import('../components/setting/MyRelation');
      },
      name: 'myrelation',
      meta: {
        title: 'myrelation',
        noCache: true
      }
    },
    /* 修改模板 */
    {
      path: 'template/:type/:groupId',
      component: function component() {
        return import('@/components/setting/TemplateBak');
      },
      name: 'Template',
      meta: {
        title: 'Template',
        noCache: true
      }
    }]
  }, {
    path: '/account',
    component: layout,
    hidden: true,
    children: [{
      path: 'index',
      component: function component() {
        return import('../components/account/Index');
      },
      name: 'Index',
      meta: {
        title: 'Index',
        noCache: true
      }
    }, {
      path: 'my',
      component: function component() {
        return import('../components/account/My');
      },
      name: 'My',
      meta: {
        title: 'My',
        noCache: true
      }
    }, {
      path: 'shop',
      component: function component() {
        return import('../components/account/Shop');
      },
      name: 'Shop',
      meta: {
        title: 'Shop',
        noCache: true
      }
    }, {
      path: 'updatepass',
      component: function component() {
        return import('../components/account/UpdatePass');
      },
      name: 'UpdatePass',
      meta: {
        title: 'UpdatePass',
        noCache: true
      }
    }]
  }, {
    path: '*',
    redirect: '/account/index',
    hidden: true
  }]
});
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import { getUserInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
var user = {
  state: {
    token: getToken(),
    name: '',
    state: '',
    shopId: 0,
    roles: [],
    usertype: '',
    avatar: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif"
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_STATE: function SET_STATE(state, status) {
      state.state = status;
    },
    SET_SHOPID: function SET_SHOPID(state, shopid) {
      state.shopId = shopid;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_USERTYPE: function SET_USERTYPE(state, usertype) {
      state.usertype = usertype;
    },
    SET_PHONE: function SET_PHONE(state, phone) {
      state.phone = phone;
    }
  },
  actions: {
    // 登出
    LogOut: function LogOut(_ref) {
      var commit = _ref.commit,
          state = _ref.state;
      return new Promise(function (resolve, reject) {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
    //登录过期
    FedLogOut: function FedLogOut(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },
    // 保存SSO的token信息
    SaveLoginInfo: function SaveLoginInfo(_ref3, token) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_TOKEN', token);
        setToken(token);
        resolve();
      });
    },
    // 获取用户信息
    GetUserInfo: function GetUserInfo(_ref4) {
      var commit = _ref4.commit,
          state = _ref4.state;
      return new Promise(function (resolve, reject) {
        getUserInfo().then(function (response) {
          var data = response.data;
          commit('SET_PHONE', data.phone);
          commit('SET_NAME', data.name);
          commit('SET_SHOPID', data.shopId);
          commit('SET_STATE', data.state);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  }
};
export default user;
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/col/style";
import _Col from "vant/es/col";
import "vant/es/row/style";
import _Row from "vant/es/row";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/notify/style";
import _Notify from "vant/es/notify";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/radio-group/style";
import _RadioGroup from "vant/es/radio-group";
import "vant/es/radio/style";
import _Radio from "vant/es/radio";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/checkbox-group/style";
import _CheckboxGroup from "vant/es/checkbox-group";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "D:\\www\\youzan\\hello-world\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\www\\youzan\\hello-world\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\www\\youzan\\hello-world\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\www\\youzan\\hello-world\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import './mock';
import './icons'; // icon

Vue.use(_Button).use(_Col).use(_Row).use(_Field).use(_CellGroup).use(_ActionSheet).use(_Notify).use(_Icon).use(_Form).use(_RadioGroup).use(_Radio).use(_Uploader).use(_Dialog).use(_Popup).use(_Checkbox).use(_CheckboxGroup).use(_Image).use(_Loading).use(_Icon).use(_Card).use(_Cell);

Vue.prototype.GetDomainOpenid = function () {
  //获取域名转成openid
  return '0000';
};

import './permission'; // permission control

import { website } from "./utils/website";
import _ from 'lodash';
Vue.config.productionTip = false;

Vue.prototype.webSite = function (key) {
  //根据不同的站点注入不同的配置文件
  var openid = store.getters.webopenid;
  return website[openid][key];
};

Vue.prototype.GoUrl = function (url) {
  this.$router.push(url);
};

Vue.prototype.GoBack = function (obj) {
  obj.$router.go(-1);
};

Vue.prototype.$moment = moment; // 赋值使用

import VueTouch from 'vue-touch';
Vue.use(VueTouch, {
  name: 'v-touch'
}); // 自定义全局组件

import DefaultBg from './components/DefaultBg';
Vue.use(DefaultBg);

_.assign(window, {
  _: _
});

console.log("####end####");
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');
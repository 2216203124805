import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import Loading from './index.vue'; // 这里是重点

var DefaultBg = {
  install: function install(Vue) {
    Vue.component('DefaultBg', Loading);
    Vue.use({
      i18n: function (_i18n) {
        function i18n(_x, _x2) {
          return _i18n.apply(this, arguments);
        }

        i18n.toString = function () {
          return _i18n.toString();
        };

        return i18n;
      }(function (key, value) {
        return i18n.t(key, value);
      })
    });
  }
}; // 导出组件

export default DefaultBg;